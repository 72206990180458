import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as React from "react";
import { ErrorMessage, useFormContext } from "react-hook-form";

import styled from "./styled";

type FormErrors<T> = Array<{
  path: keyof T;
  type: string;
  message: string;
}>;

type RestFrameworkErrors<T> = {
  [K in keyof T]?: string[];
};

export class FormValidationError<T> extends Error {
  constructor(public inner: FormErrors<T>) {
    super("form validation error");
  }

  public cast<K>(): FormValidationError<K> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this as any;
  }

  public intoForm(form: {
    setError: (path: keyof T, type: string, message: string) => void;
  }) {
    this.inner.forEach(({ path, type, message }) =>
      form.setError(path, type, message)
    );
  }

  public static fromRestFrameworkErrors<T>(data: RestFrameworkErrors<T>) {
    return new FormValidationError<T>(
      Object.entries(data).flatMap(([path, serverErrors]) => {
        if (Array.isArray(serverErrors)) {
          const firstError = serverErrors.find(x => !!x);
          if (firstError) {
            return {
              type: "restFramework",
              path: path as keyof T,
              message: firstError
            };
          }
        }
        return [];
      })
    );
  }
}

type FormTextFieldProps = {
  label: string;
  name: string;
  required?: boolean | string;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: "text" | "email" | "password";
};

export const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  label,
  required,
  fullWidth,
  disabled,
  type
}) => {
  const {
    register,
    errors,
    formState: { isSubmitting }
  } = useFormContext();
  const [showPasswords, setShowPasswords] = React.useState(false);
  const handleClickShowPassword = React.useCallback(() => {
    setShowPasswords(x => !x);
  }, []);

  return (
    <Root>
      <TextField
        label={label}
        name={name}
        required={!!required}
        inputRef={register({ required })}
        error={!!errors[name]}
        disabled={isSubmitting || disabled}
        type={
          type === "password"
            ? showPasswords
              ? "text"
              : "password"
            : type ?? "text"
        }
        fullWidth={fullWidth}
        InputProps={
          (type === "password" && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPasswords ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }) ||
          undefined
        }
      />
      <ErrorMessage name={name} as={<ValidationError />} />
      {!errors[name] && <ValidationError />}
    </Root>
  );
};

const ValidationError = styled.div`
  color: ${({ theme }): string => theme.palette.error.main};
  display: block;
  font-size: 10px;
  height: 5px;
  text-align: right;
  top: -1px;
`;

const Root = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
`;
